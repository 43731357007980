$font: klavika,sans-serif;

$tablet: 768px;
$laptop: 1024px;
$desktop: 1200px;

$dark: #646467;
$green: #708B26;
$greenDarker: #5d7420;
$creame: #EFEFE2;
$light: #EAEAEA;
$lessLight: #d3d1d1;