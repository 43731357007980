@font-face{
    font-family:"klavika";
    src:url("../fonts/klavika-bold.otf") format("opentype");
    font-weight: 700;
    font-display: swap;
}

@font-face{
    font-family:"klavika";
    src:url("../fonts/klavika-light.otf") format("opentype");
    font-weight: 300;
    font-display: swap;
}

@import 'variables.scss';
@import 'mixins.scss';
@import 'global.scss';
@import 'ui.scss';
@import 'layout.scss';
@import 'pages.scss';
@import 'animation.scss';
@import 'helpers';
@import 'dashboard';