._grid{
    width: calc(100% - 40px);
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    column-gap: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
    ._column{
        width: 100%;
    }
    &._form-grid{
        max-width: 800px;
    }
    &._form-grid-small{
        max-width: 400px;
    }
    &._grid-2{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(50% - 20px);
            }
        }
    }
    &._grid-3{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(33.33% - 30px);
            }
        }
    }
    &._grid-4{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(25% - 30px);
            }
        }
    }
    @media(min-width: $tablet){
        width: calc(100% - 60px);
        column-gap: 30px;
    }
    @media(min-width: $laptop){
        width: calc(100% - 80px);
        column-gap: 40px;
    }
}

header{
    @include transition(.5s);
    z-index: 3;
    ._grid{
        height: 100%;
    }
    nav{
        height: 100%;
        ul{
            display: flex;
            justify-content: flex-end;
            margin: 0;
            column-gap: 15px;
            height: 100%;
            align-items: center;
            // @media(min-width: 850px){
            //     column-gap: 16px;
            // }
            @media(min-width: 915px){
                column-gap: 19px;
            }
            @media(min-width: $laptop){
                column-gap: 15px;
            }
            @media(min-width: $desktop){
                column-gap: 25px;
            }
            li{
                a{
                    display: block;
                    padding: 20px 0;
                }
            }
        }
    }
    ._group{
        justify-content: space-between;
        align-items: center;
    }
    &._header-initial{
        height: 120px;
        margin-bottom: -120px;
        position: relative;
        a{
            color: #fff;
        }
        ._fancy-logo{
            position: absolute;
            top: 0;
            left: 0;
        }
        ._menu-button{
            span{
                background-color: #fff;
            }
            &._open{
                span{
                    background-color: $green;
                }
            }
        }
        @media(min-width: $laptop){
            height: 140px;
            margin-bottom: -140px;
        }
    }
    &._header-fixed{
        background-color: #fff;
        position: fixed;
        width: 100%;
        top: 0;
        visibility: hidden;
        opacity: 0;
        min-height: 67px;
        ._grid{
            height: 100%;
        }
        ul{
            height: 67px;
        }
        ._logo{
            width: 100px;
            img{
                width: 100%;
                display: block;
            }
        }
        &._show{
            top: 0;
            visibility: visible;
            opacity: 1;
        }
    }
    ._nav-list-item{
        display: none;
        @media(min-width: $tablet){
            display: block;
        }
    }
    ._nav-list-item-menu{
        @media(min-width: $tablet){
            display: none;
        }
    }
}

._flyout-menu{
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    background-color: #fff;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    @include transition(.5s);
    ul{
        margin: 0 auto;
        list-style: none;
        padding-left: 0;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        left: 100px;
        @include transition(.5s);
        li{
            text-align: center;
            a{
                display: block;
                padding: 25px 0;
                font-weight: 700;
                font-size: 30px;
            }
        }
    }
    &._show{
        visibility: visible;
        opacity: 1;
        ul{
             left: 0;
        }
    }
    @media(min-width: $tablet){
        display: none;
    }
}

._fancy-logo{
    width: 200px;
    img{
        width: 100%;
        height: 175px;
    }
    @media(min-width: $laptop){
        width: 280px;
        img{
            height: 244px;
        }
    }
}

footer{
    padding: 100px 0;
    background-color: $creame;
    ul{
        margin: 0;
    }
    ._logo{
        width: 100%;
        display: block;
        max-width: 260px;
        img{
            width: 100%;
        }
    }
    ._group{
        row-gap: 30px;
        flex-wrap: wrap;
        ._item{
            width: 100%;
        }
    }
    ._nav-list{
        // max-height: 80px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    ._nav-link{
        padding: 8px 0;
        display: inline-block;
        width: 100%;
    }
    ._footer-list{
        a{
            font-weight: 300;
            padding: 8px 0;
            display: inline-block;
            width: 100%;
        }
    }
    ._image-group{
        max-width: 330px;
        display: flex;
        justify-content: space-between;
        column-gap: 5px;
    }
    @media(min-width: $tablet){
        ._group{
            row-gap: 60px;
            column-gap: 30px;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            ._item{
                width: 40%;
            }
        }
    }
    @media(min-width: $laptop){
        ._group{
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
            ._item{
                width: auto;
                max-height: 70px;
            }
        }
        ._nav-list{
            // width: 250px;
            // max-height: 50px;
        }
        ._logo{
            width: 270px;
            position: relative;
            bottom: 17px;
            img{
                width: 100%;
            }
        }
        ._nav-link{
            padding: 0;
        }
        ._footer-list{
            a{
                padding: 0;
            }
        }
    }
}

._section-heading{
    height: 30vw;
    min-height: 320px;
    max-height: 500px;
    background-position: center;
    background-size: cover;
    position: relative;
    ._panel{
        text-align: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        padding-bottom: 60px; 
        h1{
            color: #fff;
        }
    }
}