// admin
._dashboard-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.1);
    ._menu-icon._close {
        ._hamburger-icon{
            div{
                span{       
                    @include transition;
                    &:nth-of-type(1){
                        top:50%;
                        transform:translateY(-50%) rotate(45deg);
                    }
                    &:nth-of-type(2){
                        top:50%;
                        transform:translateY(-50%) rotate(-45deg);
                    }
                }
            }         
        }
    }
    @media(min-width: $tablet){
        height: 70px;
    }
    @media(min-width: $laptop){
        height: 80px;
    }
    ._item{
        height: 100%;
    }
    a{
        display: inline-block;
    }
    ._dashboard-list{
        height: 100%;
        display: flex;
        margin: 0;
        align-items: center;
        padding-left: 0;
        p{
            margin-top: 0;
        }
        li{
            list-style: none;
            &._breadcrums{
                display: none;
                p{
                    margin: 0;
                }
                a{
                    font-weight: 500;
                    color: $dark;
                    &:hover{
                        color: $dark;
                    }
                }
                @media(min-width: $laptop){
                    display: flex;
                }
            }
            
        }
        > li{
            &:nth-of-type(1){
                padding: 10px 15px 10px 30px;
            }
            &:last-child{
                padding: 10px 30px 10px 15px;
            }
            
            @media (min-width:$tablet){
                &:nth-of-type(1){
                    padding: 10px 15px 10px 30px;
                }
                &:last-child{
                    padding: 10px 30px 10px 15px;
                }
            }
            @media (min-width:$laptop){
                &:nth-of-type(1){
                    padding: 10px 15px 10px 30px;
                }
                &:last-child{
                    padding: 10px 30px;
                }
            }
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            @media (min-width:$laptop){
                &._menu-icon{
                    display: none;
                }
            }
            > ul {
                position: absolute;
                top: 100%;
                right: 20px;
                a{
                    display: block;
                    padding: 7px 0;
                    color: #fff;
                }
                background-color: $green;
                color: #fff;
                padding: 10px 20px;
                visibility: hidden;
                opacity: 0;
                @include transition;
                border-radius: 5px;
                min-width: 150px;
                > li{
                    padding: 0;
                    
                }
                &._show{
                    visibility: visible;
                    opacity: 1;
                }
                &:before{
                    content: "";
                    width: 15px;
                    height: 15px;
                    background-color: $green;
                    position: absolute;
                    top: -7px;
                    right: 14px;
                    transform: rotate(45deg);
                }
            }
        }
    }
    ._logo-li{
        text-align: center;
        svg{
            fill: $dark;
            width: 40px;
            margin: 0 auto;
        }
        @media (min-width: $laptop) {
            width: 200px;
            text-align: center;
            // background-color: $green;
            border-right: 1px solid $light;
            a{
                margin: 0 auto;
            }
            svg{
                fill: #fff;
                width: 60px;
                margin: 0 auto;
            }
        }
    }
    ._logo{
        width: 60px;
        display: block;
        svg{
            width: 100%;
        }
        @media (min-width: $tablet){
            width: 70px;
        }
        @media (min-width: $laptop){
            width: 80px;
        }
    }
}

._dashboard-side-menu{
    position: fixed;
    top: 60px;
    left: -200px;
    color: #fff;
    width: 200px;
    bottom: 0;
    @include transition;
    z-index: 1;
    @media(min-width: $tablet){
        top: 70px;
        bottom: 0;
    }
    @media(min-width: $laptop){
        top: 80px;
        bottom: 0;
        left: 0;
    }
    ._overlay{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        bottom: 0;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
        @include transition;
        cursor: pointer;
        @media(min-width: $laptop){
            display: none;
        }
    }
    &._show{
        left: 0;
        ._overlay{
            visibility: visible;
            opacity: 1;
        }
    }
    ._list{
        margin: 0;
        height: 100%;
        background-color: $green;
        overflow-y: auto;
        padding-left: 0;
        ul{
            background-color: $greenDarker;
        }
        li{
            list-style: none;
            position: relative;
            color: #fff;
            a{
                display: block;
                padding: 12px 30px;
                color: #fff;
            }
            >ul{
                display: none;
                padding: 12px 0;
                a{
                    padding: 2px 30px;
                }
                &._show{
                    display: block;
                }
            }
        }
        ._arrow{
            &:after{
                content: "";
                position: absolute;
                top: 20px;
                right: 30px;
                display: block;
                width: 7px;
                height: 7px;
                border-right: 1px solid #fff;
                border-bottom: 1px solid #fff;
                transform: rotate(45deg);
                @include transition;
            }
            &._up{
                &:after{
                    transform: rotate(-135deg);
                }
            }
        }
    }
}

._dashboard-content{
    margin-top: 80px;
    width: 100%;
    @include transition;
    padding-bottom: 100px;
    &._slide{
        margin-left: 200px;
    }
    @media(min-width: $tablet){
        margin-top: 90px;
    }
    @media(min-width: $laptop){
        margin-top: 100px;
        padding-left: 200px;
        &._slide{
            margin-left: 0;
        }
    }
    ._section{
        padding-left: 30px;
        padding-right: 30px;
    }
}

._section-header{
    ._breadcrumbs{
        font-size: 14px;
        color: $dark;
        @media(min-width: $laptop){
            display: none;
        }
        a{
            @include transition;
            color: $dark;
            &:hover{
                color: $dark;
            }
        }
    }
    > h1{
        padding-bottom: 60px;
        padding-top: 20px;
        margin: 0;
    }
}

._form-dashboard{
    max-width: 650px;
    ._form-row{
        &:nth-of-type(1){
            margin-top: 0;
        }
    }
}

._success-message{
    color: green;
}

._error-message{
    color: red;
}

._success-group{
    padding: 15px;
    border: 1px solid green;
    border-radius: 5px;
    margin-top: 15px
}

._error-group{
    padding: 15px;
    border: 1px solid red;
    border-radius: 5px;
    margin-top: 15px
}

._buttons-group{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;
}

._table-button {
    padding: 7px;
    background-color: $light;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    min-width: 55px;
    text-align: center;
    @include transition;
    white-space: nowrap;
    &._table-button-edit{
        color: green;
    }
    &._table-button-delete{
        color: red;
    }
    &:hover{
        background-color: $lessLight;
    }
}

._button-group{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 30px;
}

._dashboard-primary-navigation{
    ul{
        padding-left: 0;
        list-style: none;
        display: flex;
        justify-content: center;
        column-gap: 30px;
        flex-wrap: wrap;
        row-gap: 30px;
        li{
            width: 100%;
            max-width: 300px;
        }
    }
    @media(min-width:$tablet){
        ul{
            flex-wrap: nowrap;
            justify-content: flex-start;
        }
    }
}

._hr-dashboard{
    background-color: $green;
    height: 1px;
}

._button-submit{
    @include transition(0.5s);
    position: relative;
    top: 0;
    left: 0;
    &._sending{
        left: 100vw;
    }
}

._button-2-large{
    color: $green;
    background-color: $light;
    border-radius: 7px;
    padding: 30px 40px;
    font-size: 24px;
    display: block;
    text-align: center;
    @include transition;
    &:hover{
        background-color: $lessLight;
    }
}