*, *:before, *:after {
    box-sizing: border-box;
}

// html{
//     scroll-behavior: smooth;
// }

body{
    margin: 0;
}

a{
    text-decoration: none;
}

img{
    max-width: 100%;
}

input, select{
    height: 40px;
    border-radius: 0;
    border: 1px solid $light;
    padding: 0px 5px;
    display: block;
    width: 100%;
}

textarea{
    border-radius: 0;
    border: 1px solid $light;
    padding: 0px 5px;
    display: block;
}

label{
    padding: 0 0 7px 0;      
    display: block;
}

form{
    text-align: left;
}