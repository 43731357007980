._section-contact{
    padding: 100px 0;
    text-align: center;
    h2,p{
        color: #fff;
    }
}

._section-hero{
    height: 100vh;
    position: relative;
    overflow: hidden;
    video{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
    }
    ._heading{
        height: 100%;
        
        position: relative;
        z-index: 1;
        h1, ._heading-3{
            color: #fff;
        }
        ._grid{
            height: 100%;
            align-items: center;
        }
    }
    ._buttons{
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        z-index: 1;
        ._group{
            justify-content: center;
            padding-bottom: 15px;
        }
    }
    ._scrollDown-button{
        width: 100%;
        text-align: center;
        cursor: pointer;
        background-color: transparent;
        border: none;
        // display: none;
        // @media(min-width: $tablet){
        //     display: block;
        // }
    }
}

._locations-container{
    position: relative;
    height: 800px;
    width: 100%;
    overflow: hidden;
    iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 859px;
        margin-top: -533px;
    }
}

._section-products{
    padding: 100px 0;
    ._grid{
        justify-content: center;
        row-gap: 60px;
    }
    ._card{
        box-shadow: 1px 2px 3px #0000001A;
        padding: 15px;
        h4{
            font-weight: 700;
            font-size: 20px;
            margin: 30px 0 0 0;
        }
        ._group{
            justify-content: space-between;
            p{
                margin: 0;
                span{
                    color: $green;
                }
            }
        }
        ._button-group{
            display: flex;
            justify-content: center;
        }
        ._button{
            position: relative;
            margin: 0 auto;
            top: 40px;
            background-color: #fff;
            &:hover{
                color: $dark;
            }
        }
    }
}

._section-contact-us{
    padding: 100px 0;
    h2{
        text-align: center;
    }

    ._panel{
        max-width: 800px;
        margin: 20px auto 100px auto;
    }
}

._section-product-banner{
    height: 100vh;
    background-size: cover;
    background-position: center;
    
}

._section-product-content{
    display: flex;
    flex-wrap: wrap;
    row-gap: 60px;
    padding: 60px 0;
    ._group-heading{
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        column-gap: 15px;
    }
    h4{
        color: $green;
        font-weight: 300;
        font-size: 20px;
        margin: 0;
        white-space: nowrap;
    }
    h1{
        font-weight: 700;
        font-size: 30px;
        line-height: 30px;
        margin: 0;
        span{
            font-weight: 300;
        }
        @media(min-width: $tablet){
            font-size: 35px;
            line-height: 35px;
        }
        @media(min-width: $laptop){
            font-size: 40px;
            line-height: 40px;
        }
    }
    ._embed-container{
        width: 100%;
        position: relative;
        overflow: hidden;
        height: 0;
        height: 50vw;
        max-height: 450px;
        max-width: 800px;
        margin: 0 auto;
        background-color: $light;
        video{
            width: 100%;
        }
    }
    ._heading-characters{
        color: $green;
    }
    
    ._inner{
        max-width: 800px;
        margin: 0 auto;
        ul{
            padding-left: 15px;
            li{
                font-weight: 300;
                line-height: 26px;
            }
        }
        ._button-group{
            display: flex;
            justify-content: center;
            column-gap: 30px;
            row-gap: 30px;
            flex-wrap: wrap;
            margin: 30px 0;
            ._button{
                display: flex;
                align-items: center;
                svg{
                    fill: $green;
                    @include transition;
                }
                @media(min-width:$laptop){
                    &:hover{
                        svg{
                            fill: #fff;
                        }
                    }
                }
            }
        }
    }
    @media(min-width: $tablet){
        ._panel{
            display: flex;
            flex-wrap: wrap;
            p{
                width: 100%;
            }
            ul{
                width: 50%;
            }
        }
    }
}

._gallery{
    display: flex;
    justify-content: center;
    column-gap: 30px;
    row-gap: 30px;
    flex-wrap: wrap;
    ._caption{
        text-align: center;
        font-weight: 300;
    }
    ._gallery-item{
        img{
            width: auto;
        }
    }
    @media(min-width: $tablet){
        ._gallery-item{
            max-width: calc(50% - 30px);
        }
    }
}

._section-privacy-policy{
    padding: 100px 0;
    ._panel{
        width: 800px;
        margin: 0 auto;
    }
    li{
        font-weight: 300;
    }
    h3{
        font-weight: 700;
    }
}

._section-locations{
    padding: 100px 0;
    text-align: center;
    label{
        font-size: 20px;
    }
}

._section-locations{
    text-align: left;
    padding-bottom: 0;
    p{
        margin-top: 0;
        margin-bottom: 15px;
    }
    ._panel{
        display: flex;
        flex-wrap: wrap;
        ._panel-item{
            width: 50%;
        }
        h3{
            width: 100%;
            
        }
    }
}

._section-auth{
    padding: 100px 0;
    ._panel{
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
    }
}

._char{
    ul{
        @media(min-width: $tablet){
            display: flex;
            max-height: 250px;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: flex-start;
            width: 100%!important;
            li{
                max-width: 250px;
                padding-right: 10px;
            }
        }
    }
}

._selectProvince{
    width: 100%;
    max-width: 350px;
    margin-bottom: 100px;
    min-height: 60px;
    padding: 0 20px;
    font-size: 20px;
    background-color: $green;
    color: #fff;
    option{
        background-color: #fff;
        color: $dark;
    }
}