*{
    font-family: $font;
    color: $dark;
}

h1{
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    margin: 0;
    @media(min-width: $tablet){
        font-size: 45px;
        line-height: 45px;
    }
    @media(min-width: $laptop){
        font-size: 60px;
        line-height: 60px;
    }
}

h2{
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    margin: 0;
    span{
        font-weight: 300;
    }
    @media(min-width: $tablet){
        font-size: 35px;
        line-height: 35px;
    }
    @media(min-width: $laptop){
        font-size: 40px;
        line-height: 40px;
    }
}

h3, ._heading-3{
    font-weight: 300;
    font-size: 26px;
    line-height: 40px;
    margin: 0;
    @media(min-width: $tablet){
        font-size: 28px;
        line-height: 50px;
    }
    @media(min-width: $laptop){
        font-size: 30px;
        line-height: 60px;
    }
}

p{
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    margin: 24px 0;
}

strong{
    font-weight: 700;
}

._nav{
    li{
        font-weight: 700;
        font-size: 12px;
        line-height: 27px;
        @media(min-width: 850px){
            font-size: 14px;
        }
        @media(min-width: 915px){
            font-size: 15px;
        }
        @media(min-width: $laptop){
            font-size: 16px;
        }
        @media(min-width: $desktop){
            font-size: 18px;
        }
    }
    ul{
        padding-left: 0;
        list-style: none;
    }
}

footer{
    li{
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
    }
    ul{
        padding-left: 0;
        list-style: none;
    }
}

._button{
    background-color: transparent;
    border: 1px solid $dark;
    padding: 10px 25px;
    border-radius: 10px 0;
    min-width: 194px;
    min-height: 45px;
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    @include transition;
    &._button-1{
        color: #fff;
        border-color: #fff;
    }
    &._button-2{
        color: #fff;
        background-color: $green;
        border-color: $green;
    }
    &._button-3{
        color: $green;
        border-color: $green;
    }
    @media(min-width:$laptop){
        &:hover{
            background-color: $dark;
            color: #fff;
        }
        &._button-1{
            &:hover{
                background-color: #fff;
                color: $dark;
            }
        }
        &._button-2{
            &:hover{
                background-color: #fff;
                color: $green;
                border-color: #fff;
                border-color: $green;
            }
        }
        &._button-3{
            &:hover{
                background-color: $green;
            }
        }
    }
}

._group{
    display: flex;
    column-gap: 15px;
    row-gap: 15px;
    flex-wrap: wrap;
    @media(min-width: $tablet){
        column-gap: 20px;
        row-gap: 20px;
    }
    @media(min-width: $laptop){
        column-gap: 30px;
        row-gap: 30px;
    }
}

._menu-button{
    width: 45px;
    height: 45px;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: none;
    span{
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $green;
        left: 0;
        @include transition;
        &:nth-of-type(1){
            top: 7px;
        }
        &:nth-of-type(2){
            top: 50%;
            transform: translateY(-50%);
        }
        &:nth-of-type(3){
            bottom: 7px;
        }
    }
    &._open{
        span{
            &:nth-of-type(1){
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
            }
            &:nth-of-type(2){
                visibility: hidden;
                opacity: 0;
            }
            &:nth-of-type(3){
                top: 50%;
                transform: translateY(-50%) rotate(-45deg);
            }
        }
    }
}

._split-row{
    ._split-column{
        &:nth-of-type(1){
            height: 76vw;
            background-position: center;
            background-size: cover;
        }
    }
    ._split-grid{
        width: calc(100% - 40px);
        margin: 0 auto;
        max-width: 600px;
        padding: 30px 0;
    }
    @media(min-width: $tablet){
        display: flex;
        ._split-column{
            width: 50%;
            &:nth-of-type(1){
                height: 38vw;
            }
        }
        &:nth-child(even){
            ._split-column:nth-of-type(1){
                order: 2;
            }
        }
        ._split-grid{
            display: flex;
            height: 100%;
            align-items: center;
        }
        ._content{
            max-height: 20vw;
            overflow: auto;
        }
    }
    @media(min-width: $laptop){
        ._content{
            max-height: 24vw;
        }
    }
}

._hr{
    width: 100%;
    height: 1px;
    background-color: $light;
}

._group-section-heading{
    flex-wrap: nowrap;
    align-items: center;
    padding-bottom: 60px;
    h2{
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
        margin: 0;
        white-space: nowrap;
        @media(min-width: $tablet){
            font-size: 45px;
            line-height: 45px;
        }
        @media(min-width: $laptop){
            font-size: 60px;
            line-height: 60px;
        }
    }
}

._group-section-subheading{
    flex-wrap: nowrap;
    align-items: center;
    padding-bottom: 60px;
    h2{
        margin: 0;
        white-space: nowrap;
    }
}

._form-row{
    @media(min-width: $tablet){
        display: flex;
        column-gap: 30px;
        flex-wrap: nowrap;
    }    
    ._form-group{
        width: 100%;
        margin: 20px 0;
        ._form-element{
            width: 100%;
        }
    }
    ._button{
        margin-top: 30px;
    }
}

._success-message{
    color: green;
}

._error-message{
    color: red;
}

._success-group{
    padding: 15px;
    border: 1px solid green;
    border-radius: 5px;
    margin-top: 15px
}

._error-group{
    padding: 15px;
    border: 1px solid red;
    border-radius: 5px;
    margin-top: 15px;
    width: 100%;
    display: block;
}

.App {
    text-align: center;
}

.App-logo {
animation: App-logo-spin infinite 20s linear;
height: 80px;
}

.App-header {
background-color: #222;
height: 150px;
padding: 20px;
color: white;
}

.App-title {
font-size: 1.5em;
}

.App-intro {
font-size: large;
}

@keyframes App-logo-spin {
from { transform: rotate(0deg); }
to { transform: rotate(360deg); }
}

._map{
    position: relative;
    padding-bottom: 60%;
    iframe{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}

._social-icons{
    display: flex;
    margin: 30px 0;
    justify-content: center;
    a{
        display: inline-block;
        margin: 0 5px;
    }
    @media(min-width: $tablet){
        justify-content: flex-start;
        column-gap: 10px;
        a{
            margin: 0;
        }
    }
    @media(min-width: $laptop){
        margin-top: 60px;
    }
}